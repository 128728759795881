@import '../../../../styles/all.scss';

.c-LoginPage-LoginForm {
  &__or {
    position: relative;
    margin: 24px 0;
    text-align: center;
    height: 12px;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $disabled-button-bg-color;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    &__text {
      @include fontRegularCMC(12px, #aaa);
      padding: 0 12px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  &__img {
    display: flex;
    margin-bottom: 32px;
  }

  &__form {
    :global {
      .c-Button {
        margin-top: 24px;
        padding: 15px;
      }

      .c-Input {
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__form__link {
    text-align: right;

    a {
      @include fontRegularCMC(14px, #666);
      font-weight: 500;
      text-align: right;
      text-decoration: underline;
    }
  }

  &__error-message {
    @include fontRegularCMC(14px, #db3952);
    margin-top: 12px;
  }
}
