@import '../../styles/all.scss';

.c-LoginPage {
  width: 100vw;
  background-color: #f6f6f6;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @include for-desktop {
    height: initial;
  }

  &__container {
    width: 100%;
    height: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: row;

    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;

    @include for-desktop {
      width: 787px;
      height: unset;
      max-height: 787px;
      max-width: unset;
    }
  }

  &__media {
    width: 272px;
    display: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    @include for-desktop {
      display: block;
    }
  }

  &__form {
    flex-grow: 1;

    padding: 0 24px;
    margin: auto;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
